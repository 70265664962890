<template>
  <div class="w-full bg-primary p-1 flex px-8">

    <div class="w-1/3 flex items-center">
      <router-link :to="backUrl" class="h-16" v-if="logoUrl">
        <!-- <img class="h-16" src="@/assets/svg/efteling-white.svg" alt=""> -->
        <img class="h-16" :src="logoUrl" alt="">
      </router-link>
    </div>

    <div class="w-1/3 flex flex-col items-center justify-center text-white">
      <div class="">{{ date }}</div>
      <div class="text-3xl font-medium">{{ time }}</div>
    </div>

    <div class="w-1/3 flex text-white items-center justify-end">
      <div>
        <div class="text-3xl">Joren V.</div>
        <div class="">Operator</div>
      </div>
      <div class="ml-2">
        <div class="rounded-full overflow-hidden w-20">
          <img src="@/assets/svg/logo.svg" class="w-full">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import store from '../store'

export default {
  data () {
    return {
      date: '',
      time: ''
    }
  },
  mounted () {
    setInterval(() => {
      const now = moment(new Date())

      this.date = now.format('dddd D MMMM')
      this.time = now.format('HH:mm')
    })
  },
  computed: {
    parkId () {
      if (this.$route.params.park) {
        return Number(this.$route.params.park)
      } else {
        return false
      }
    },
    backUrl () {
      return `/parks/${this.parkId ?? ''}`
    },
    logoUrl () {
      if (this.parkId) {
        const park = store.parks.find(park => park.id === this.parkId)

        if (park?.image) return park.image
      }

      return null
    }
  }
}
</script>
