<template>
  <div class="flex flex-col">
    <navbar />

    <div class="flex flex-row flew-wrap p-8">
      <div
        v-for="park in parks" :key="park.id"
        class="p-2 w-1/4"
      >
        <router-link
          :to="'/parks/' + park.id"
          class="flex w-full h-full bg-white border border-gray-300 rounded-lg shadow-lg p-4"
          >
          <h2 class="text-xl">
            {{ park.name }}
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar
  },
  mounted () {

  },
  computed: {
    parks () {
      return store.parks
    }
  }
}
</script>
